export const LANDING = 'https://www.join.videoh.me/';
export const USERNOTCONFIRMED_CODE = 'UserNotConfirmedException';
export const REPLY_TO_EMAIL = 'founders@remiovr.com';
export const API_VERSION = process.env.REACT_APP_API_VERSION || '';
export const TEAM_ADMIN = 'Team Admin';
export const PARTICIPANT = 'Participant';
export const NORMAL_USER = 'NormalUser';
export const DEVELOPER = 'Developer';
export const SUPER_USER = 'SuperUser';
export const ERROR = 'Error';
export const NOTIFICATION = 'Notification';
export const PUBLIC_SERVER_UID = 'PUBLIC';
export const PUBLIC_SERVER_NAME = 'remiopublic';
export const PUBLIC_SERVER_VISIBLE_NAME = 'Remio Town Hall';
export const DEFAULT_SETTINGS_SERVER_NAME = 'defaultteamsettings';
export const AUTH0_PROVIDER = process.env.REACT_APP_AWS_COGNITO_AUTH0_PROVIDER;
export const USER_POOL_DOMAIN =
  process.env.REACT_APP_AWS_COGNITO_USER_POOL_DOMAIN;
export const USER_POOL_CLIENT_ID =
  process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID;
export const IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
export const CHANNEL_NAME = process.env.REACT_APP_API_CHANNEL_NAME;
export const WEBSOCKET_SERVER_URL = process.env.REACT_APP_WEBSOCKET_SERVER_URL;
export const SEND_PLAYER_INFO_DATA =
  process.env.REACT_APP_SEND_PLAYER_INFO_DATA;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;
export const WEBSOCKET_SERVER_URL_LOCAL =
  process.env.REACT_APP_WEBSOCKET_SERVER_URL_LOCAL;
export const WEBSOCKET_SERVER_URL_MUMBAI =
  process.env.REACT_APP_WEBSOCKET_SERVER_URL_MUMBAI;
export const META_STORE_ID = process.env.REACT_APP_META_APP_ID;
export const IS_ENTERPRISE =
  (process.env.REACT_APP_IS_ENTERPRISE || '') === 'true';

export const SERVER_ROLES = {
  ADMIN: 0,
  PARTICIPANT: 1,
  MODERATOR: 2,
  MODERATOR_TRAINEE: 3,
};

export const USER_ROLES = {
  NORMAL_USER: 0,
  DEVELOPER: 1,
  SUPER_USER: 2,
  HOST: 3,
};

export const MEMBER_STATUS = {
  SUSPENDED_3: 3,
  SUSPENDED_7: 7,
  SUSPENDED_30: 30,
  BANNED: 'BANNED',
};

export const TEAM_QUERY_STRING = {
  SERVER_SETTINGS:
    'basics=1&teamServers=1&availablePortals=1&teamPortals=1&availableTeamSpaces=1&defaultTeamSpace=1',
  MEMBERS: 'members=1',
  FILES: 'presentations=1&models=1',
  LINKS: 'videos=1',
};

export const SERVER_HOSTED_MODES = {
  Fully_Unlocked: 0,
  Team_Admin: 1,
  Fully_Locked: 2,
};

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AWS_CONFIG = {
  apiAddress: process.env.REACT_APP_AWS_API_GATEWAY_ID,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  webClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
};

export const PAGE_REFRESH_INTERVAL = 5;

export const AGORA_SCREEN_SHARE_OPTIONS = {
  SCREEN_SHARE_JOIN: 200,
  SCREEN_SHARE_LEAVE: 201,
  SCREEN_SHARE_STOP_BROWSER: 202,
};

export const CUSTOM_EVENTS = {
  tempPreview: 'tempPreview',
  reloaded: 'reloaded',
  sharedPhoto: 'sharedPhoto',
  onClick: 'onClick',
  onShiftKey: 'onShiftKey',
  onCtrlClick: 'onCtrlClick',
  onImageClick: 'onImageClick',
  downloadImage: 'downloadImage',
  deleteImage: 'deleteImage',
  sharePhoto: 'sharePhoto',
  updatedThumbnails: 'updatedThumbnails',
  next: 'next',
  shareableLink: 'shareableLink',
  accessRevoked: 'accessRevoked',
};

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  'X-Remio-Platform': IS_ENTERPRISE ? 'enterpriseweb' : 'web',
};

export const DEFAULT_HEADERS_V2 = {
  ...DEFAULT_HEADERS,
  'X-Remio-Response-Version': 'v2',
};
