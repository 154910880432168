import { Auth, API } from 'aws-amplify';

import {
  API_VERSION,
  DEFAULT_HEADERS,
  DEFAULT_HEADERS_V2,
} from '../constants/Constants';
import { CLOUD } from '../constants/Routes';

import { sanitizeS3FileName } from './commonService';

export type StandalonePlatform = 'standalone' | 'windowsenterprise';

export function getServerListApi(
  accessToken: string
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  let path = `/${API_VERSION}/teams/v3/list?limit=300`;
  if (document.location.pathname.split('/')[1] === CLOUD) {
    path = `/${API_VERSION}/teams/v3/list?limit=300&page=${CLOUD}`;
  }
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function getGuestServerListApi(): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/list_guest_servers`;
  const myInit = {
    headers: DEFAULT_HEADERS,
  };

  return API.get(apiName, path, myInit);
}

export function getUserDetailsApi(
  accessToken: string,
  userId: string | null = null
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = userId
    ? `/${API_VERSION}/users/front/${userId}`
    : `/${API_VERSION}/users/front`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function refreshUserCode(
  userId: string,
  accessToken: string
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/refresh_code/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function getGalleryImages(
  accessToken: string,
  uuid = null,
  teamId = null,
  u_page = 1,
  t_page = 1,
  limit = 25
): Promise<RemioResponseV1> {
  let defaults = 0;
  if (uuid && u_page === 1) {
    defaults = 1;
  }
  // eslint-disable-next-line
  const path = `/${API_VERSION}/cloud/files/gallery?uuid=${uuid}&teamId=${teamId}&u_page=${u_page}&t_page=${t_page}&limit=${limit}&defaults=${defaults}&all=1`;
  const myInit = { headers: { ...DEFAULT_HEADERS, token: accessToken } };
  return API.get(API_VERSION, path, myInit);
}

export async function uploadGalleryImage(
  accessToken: string,
  uuid: string,
  filename: string,
  type: string
) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/cloud/files/upload_gallery_image`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: {
      filename: sanitizeS3FileName(filename),
      uuid,
      type,
    },
  };
  const response = await API.post(apiName, path, myInit);
  return JSON.parse(response.body);
}

export function deleteGalleryImage(
  accessToken: string,
  uuid = null,
  filename = null,
  type = 'User'
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/cloud/files/delete_gallery_image?uuid=${uuid}&filename=${filename}&type=${type}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.get(apiName, path, myInit);
}

export function sendResetEmailCode(email: string) {
  // Send confirmation code to user's email
  return Auth.forgotPassword(email);
}

export function forgotPasswordSubmit(
  email: string,
  code: string,
  new_password: string
): Promise<RemioResponseV1> {
  // Send confirmation code to user's email
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/front/forgot_password`;
  const myInit = {
    headers: DEFAULT_HEADERS,
    body: {
      email: email,
      password: new_password,
      confirmationCode: code,
    },
  };

  return API.put(apiName, path, myInit);
}

export function signIn(
  email: string,
  password: string
): Promise<RemioResponseV2<SignInResponse>> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/signin`;
  const myInit = {
    headers: DEFAULT_HEADERS_V2,
    body: { email, password },
  };

  return API.post(apiName, path, myInit);
}

export function getUserDetailsWithToken(accessToken: string) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/login/token`;
  const myInit = {
    headers: DEFAULT_HEADERS,
    queryStringParameters: {
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function getTeamInfo(
  accessToken: string,
  teamUUID: string,
  qry_params: string
): Promise<RemioResponseV1> {
  // &members=1&videos=1&presentations=1&models=1
  const qry_str =
    qry_params ||
    'basics=1&teamServers=1&availablePortals=1&teamPortals=1&availableTeamSpaces=1&defaultTeamSpace=1';
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/front/${teamUUID}?${qry_str}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function getTeamInfoForCloud(
  accessToken: string,
  teamUUID: string
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/get_team_info/${teamUUID}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };

  return API.get(apiName, path, myInit);
}

export function getPublicServerInfo(
  serverName: string
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/teams/public_info/${serverName}`;

  const myInit = {
    headers: DEFAULT_HEADERS,
  };

  return API.get(apiName, path, myInit);
}

export function setTeamServerPublicPrivate(
  accessToken: string,
  teamUUID: string,
  body: unknown
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${apiName}/teams/set_public_server/${teamUUID}`;
  const myInit = {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  };
  return API.post(apiName, path, myInit);
}

export function setTeamCodeApi(
  accessToken: string,
  teamUUID: string,
  body: unknown
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${apiName}/teams/set_team_code/${teamUUID}`;
  const myInit = {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  };

  return API.post(apiName, path, myInit);
}

export async function getStandaloneSignedURL(
  platform: StandalonePlatform = 'standalone'
): Promise<string> {
  const apiName = API_VERSION;
  const path = `/${apiName}/public/download/${platform}`;
  const myInit = { headers: DEFAULT_HEADERS };
  const response: RemioResponseV1 = await API.get(apiName, path, myInit);
  if (!response.body) {
    throw new Error('Response body is empty');
  }

  return (JSON.parse(response.body) as { url: string }).url;
}

export function checkEmailAndPairDevice(
  uuid: string,
  deviceId: string,
  oculusId: string | null = null,
  oculusUsername: string | null = null,
  oculusAppScopedId: string | null = null,
  serverCode: string | null = null
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  let path = `/${API_VERSION}/emails/pair-device?uuid=${uuid}&deviceId=${deviceId}`;

  if (oculusId) {
    path += `&oculusId=${oculusId}`;
  }

  if (oculusUsername) {
    path += `&oculusUsername=${oculusUsername}`;
  }

  if (oculusAppScopedId) {
    path += `&oculusAppScopedId=${oculusAppScopedId}`;
  }

  if (serverCode) {
    path += `&serverCode=${serverCode}`;
  }

  const init = { headers: DEFAULT_HEADERS };
  return API.put(apiName, path, init);
}

export function getSharedTeamPhotos(
  accessToken: string,
  teamId: string
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${apiName}/teams/shared/photos/${teamId}`;
  const myInit = { headers: { ...DEFAULT_HEADERS, token: accessToken } };
  return API.get(apiName, path, myInit);
}

export function postSharedTeamPhoto(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${apiName}/users/cloud/share/photos`;
  const myInit = { headers: { ...DEFAULT_HEADERS, token: accessToken }, body };
  return API.put(apiName, path, myInit);
}

export function uploadUserPhoto(
  accessToken: string,
  fileName: string
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/users/cloud/upload_photo/${fileName}`;
  const myInit = { headers: { ...DEFAULT_HEADERS, token: accessToken } };
  return API.get(apiName, path, myInit);
}

export async function downloadGalleryFile(
  accessToken: string,
  fileName: string,
  uuid: string,
  type: string
) {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/cloud/files/download_gallery_image/${fileName}?uuid=${uuid}&type=${type}`;
  const myInit = { headers: { ...DEFAULT_HEADERS, token: accessToken } };
  const response = await API.get(apiName, path, myInit);
  return JSON.parse(response.body);
}

export function joinServerViaCodeApi(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${apiName}/teams/join_via_code`;
  return API.put(apiName, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function getAppData(url: string): Promise<RemioResponseV1> {
  const urlParams = url.split('/');
  const appId = urlParams[5].split('?')[0];
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/team/portals/get_app_data/${appId}`;
  return API.get(apiName, path, {});
}

export function addNewPortal(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${API_VERSION}/team/portals/new`;
  const myInit = {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  };
  return API.post(apiName, path, myInit);
}

export function saveUserName(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const apiName = API_VERSION;
  const path = `/${apiName}/users/save_name`;
  const myInit = { headers: { ...DEFAULT_HEADERS, token: accessToken }, body };
  return API.post(apiName, path, myInit);
}

export function deleteGalleryVideo(
  accessToken: string,
  uuid = null,
  filename = null
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/users/cloud/files/delete_gallery_video?uuid=${uuid}&filename=${filename}`;
  return API.del(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function getUserVideos(
  accessToken: string,
  page = 1,
  limit = 25
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/users/cloud/get_video_urls?page=${page}&limit=${limit}`;
  return API.get(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function getPostPresignedUrlPortalImage(
  accessToken: string,
  filename: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/team/portals/preview/${encodeURIComponent(
    filename
  )}`;
  return API.post(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function updateServerHostedMode(
  accessToken: string,
  body: { teamId: string }
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/teams/update_hosted_mode/${body.teamId}`;
  return API.put(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function removeTeamMember(
  accessToken: string,
  teamId: string,
  userId: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/teams/remove_member/${teamId}/${userId}`;
  return API.get(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function getDeepLinks(
  accessToken: string,
  teamId: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/deeplink/list/${teamId}`;
  return API.get(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function updateObjectACL(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/cloud/files/update/acl`;
  return API.put(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function getSharedGalleryImages(
  accessToken: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/cloud/files/shared/gallery`;
  return API.get(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function addDeepLinks(
  accessToken: string,
  body: { teamId: string }
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/deeplink/create/${body.teamId}`;
  return API.post(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function deleteDeepLinks(
  accessToken: string,
  body: { teamId: string; uid: string }
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/deeplink/delete/${body.teamId}/${body.uid}`;
  return API.put(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function editDeepLinks(
  accessToken: string,
  body: { teamId: string; uid: string }
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/deeplink/update/${body.teamId}/${body.uid}`;
  return API.put(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function fetchDeepLinks(deepLinkId: string): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/deeplink/fetch/${deepLinkId}`;
  return API.get(API_VERSION, path, { headers: DEFAULT_HEADERS });
}

export function downloadGalleryImageFromKey(
  accessToken: string,
  key: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/cloud/files/download_gallery_image_from_key?key=${key}`;
  return API.get(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
  });
}

export function getSharedServersByPhoto(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/teams/shared/photo/server`;
  return API.post(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function revokeSharedAccessForServer(
  accessToken: string,
  body: unknown
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/users/shared/photo/revoke`;
  return API.post(API_VERSION, path, {
    headers: { ...DEFAULT_HEADERS, token: accessToken },
    body,
  });
}

export function getAvailableTags(
  accessToken: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/users/tags/available`;
  const myInit = {
    headers: {
      'content-type': 'application/json',
      token: accessToken,
    },
  };
  return API.get(API_VERSION, path, myInit);
}

export function getUserTags(
  accessToken: string,
  userId: string
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/users/tags/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
  };
  return API.get(API_VERSION, path, myInit);
}

export function updateUserTags(
  accessToken: string,
  userId: string,
  tags: string[]
): Promise<RemioResponseV1> {
  const path = `/${API_VERSION}/users/tags/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS,
      token: accessToken,
    },
    body: {
      tags,
    },
  };
  return API.put(API_VERSION, path, myInit);
}

export function getAvailableLabels(
  accessToken: string
): Promise<RemioResponseV2<UserLabels>> {
  const path = `/${API_VERSION}/users/labels/available`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: accessToken,
    },
  };
  return API.get(API_VERSION, path, myInit);
}

export function getUserLabels(
  accessToken: string,
  userId: string
): Promise<RemioResponseV2<UserLabels>> {
  const path = `/${API_VERSION}/users/labels/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: accessToken,
    },
  };
  return API.get(API_VERSION, path, myInit);
}

export function updateUserLabels(
  accessToken: string,
  userId: string,
  labels: number[]
): Promise<RemioResponseV2<null>> {
  const path = `/${API_VERSION}/users/labels/${userId}`;
  const myInit = {
    headers: {
      ...DEFAULT_HEADERS_V2,
      token: accessToken,
    },
    body: {
      labels,
    },
  };
  return API.put(API_VERSION, path, myInit);
}
